import QRCode from "react-qr-code";
import { io } from "socket.io-client";
import { useEffect, useState } from "react";

//set the custom id
const socketID = 123456;

const socket = io(process.env.REACT_APP_SERVER_URL,  {
  query: { socketID }
});

function App() {
  
  const [sessionName, setSessionName] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [pageSate, setPageState] = useState({state:'loading', message:'Running new connection'});

  const createSessionForWhatsapp = () => {
    //send the sessionName to socket 
    socket.emit('createSession', {id: sessionName});
    //disable the button
    // put loading
  }

  const refreshPage = () => {
    window.location.reload(false); // Set to true for a full reload from the server
    //disable the button
    // put loading
  }
  
  useEffect( () => {
    socket.emit('connected', 'Connected to Client');

    //if disconnected
    socket.on('disconnect', (data) => {
      setPageState({state:'disconnected', message:'Socket disconnected'});
      //reconnect or reload the page
    })

    //if connected to backend
    socket.on('connected', (data) =>{
      setPageState({state:'connected', message:data});
    })

    //if connected to backend
    socket.on('createSession', (data) =>{
      setPageState({state:'createSession', message:data});
    })

    //when loading screen
    socket.on('loading_screen', (data) =>{
      setPageState({state:'loading_screen', message:data});
    })
    
    //if qr code is set
    socket.on("qr", (data) => {
      const { qr } = data;
      setQrCode(qr);
      setPageState({state:'qrCode', message:"QR code generated"});
    });
    
    //if authentication is successful
    socket.on("authenticated", (data) => {
      setPageState({state:'authenticated', message:data});
    })

    //if authentiation failed
    socket.on("auth_failure", (data) => {
      setPageState({state:'auth_failure', message:data});
    })

    //if whatsapp client is ready
    socket.on("ready", (data) => {
      setPageState({state:'ready', message:data});
    })

    //if whatsapp client is saved
    socket.on("remote_session_saved", (data) => {
      setPageState({state:'remote_session_saved', message:data});
    })
  }, []);


let display = '';
if(pageSate.state === 'disconnected'){
  display = (
    <div>
      <button className="j-margin j-btn j-padding color1 j-round" 
        onClick={refreshPage}>
          Reconnect Session
      </button>
    </div>
  );
}else if(pageSate.state === 'connected'){
  display = (
    <div>
      <div className="j-center">
        <center>
        <input 
          type='text' className="j-input j-border-2 j-round"
          value={sessionName} style={{maxWidth:'400px',width:'90%'}}
          placeholder="Enter your session name"
          onChange={ 
            (e) => { setSessionName(e.target.value) } 
          }
        />
        <button className="j-margin j-btn j-padding color1 j-round" 
          onClick={createSessionForWhatsapp}>
            Create/Validate Session
            </button>
        </center>
      </div>
    </div>
  );
}else if(pageSate.state === 'qrCode'){
  display = (
    <div>
      <div style={{marginBottom:'8px'}}>
        <b>Scan the QR code below on your whatsapp</b>
      </div>
      <div>
        <QRCode value={qrCode} />
      </div>
    </div>
  );
}


  return (
    <div className="j-center">
      <br/>
      <div className="j-margin j-xlarge">
        <b>Whatsapp Web Client</b>
      </div>
      <div className="j-margin text-color1 j-bolder j-large">
        <i>{pageSate.message}</i>
      </div>
      {display}
    </div>
  );
}

export default App;